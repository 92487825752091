import React, {useState} from 'react'
import bgImg from '../../assets/illustration-01.svg';
import ResponsivePlayer from './ResponsivePlayer';
import { XIcon } from '@heroicons/react/solid';


const Main = () => {
	return (
		<div className='mb-16'>
			<img src={bgImg} alt='/' className='absolute top-2 w-full h-40 z-[-1]'/>

			<div className='container relative mx-auto text-center my-16'>
				<div data-aos='fade-up'>
					<h1 className='text-5xl inline'>Charbors</h1>
					<p className='mx-8 md:mx-16 lg:mx-48 px-4 text-xl font-medium my-3 py-4'>Charbors is a groundbreaking platform that connects EV drivers with homeowners who have EV chargers, creating a seamless and affordable charging experience. By enabling homeowners to monetize their idle EV chargers, Charbors is revolutionizing the way we approach EV charging and fostering a new era of shared green energy.</p>
				</div>
			</div>
		</div>
  )
}

export default Main
