import React from 'react'
import featuresDashboardDriverImg from '../assets/features-dashboard-driver.png';
import featuresDashboardHomeownerImg from '../assets/features-dashboard-homeowner.png';
import featuresOrderFinishDriverImg from '../assets/features-order-finish-driver.png';
import featuresOrderFinishHomeownerImg from '../assets/features-order-finish-homeowner.png';
import featuresHistoryDriverImg from '../assets/features-history-driver.png';
import featuresHistoryHomeownerImg from '../assets/features-history-homeowner.png';

const Features = () => {

	const Insights = (props) => {
		return (
				<div className='mx-4 mb-2'>
					<h3 className='text-2xl font-semibold pb-4'>{props.title}</h3>
					<p className='text-xl md:text-lg'>
						{props.content}
					</p>
				</div>

		)
	}

	return (
		<div className='container mx-auto'>
			<div data-aos='zoom-in' className='text-center'>
				<h1 className='text-5xl'>Seamless Charging Experience</h1>
				<p className='mx-8 md:mx-16 lg:mx-48 text-xl mt-2'>
					Connecting Drivers and Homeowners Charbors offers two intuitive apps designed to streamline the charging process for both EV drivers and homeowners.
        </p>
			</div>

			<div className='mb-12'></div> {/* Sections Space */}

			<div data-aos='zoom-in' className='grid gap-8 my-8'>
				<div className='grid grid-cols-1 md:grid-cols-3 items-center gap-4 p-6 bg-gray-100 border border-gray-100 rounded-xl mx-8'>
					<Insights title="Effortless EV Charger Discovery" content="EV drivers can easily locate available home EV chargers on the Charbors app, while homeowners can list their EV chargers on the Charbors EV Charger app. The map-based interface ensures a seamless connection between drivers and hosts."/>
					<div className='w-[250px] md:w-[220px] lg:w-[300px] mx-auto'>
						<h3 className='text-xl text-center font-semibold pb-2'>Driver</h3>
						<img src={featuresDashboardDriverImg} alt='/' />
					</div>
					<div className='w-[250px] md:w-[220px] lg:w-[300px] mx-auto'>
						<h3 className='text-xl text-center font-semibold pb-2'>Host</h3>
						<img src={featuresDashboardHomeownerImg} alt='/' />
					</div>
				</div>


				<div className='grid grid-cols-1 md:grid-cols-3 items-center gap-4 p-6 bg-gray-100 border border-gray-100 rounded-xl mx-8'>
					<Insights title="Smooth Charging Requests and Collaboration" content="Initiating a charging request is simple for EV drivers using the Charbors app. Homeowners can promptly accept requests through the Charbors EV Charger app, allowing for efficient communication and coordination throughout the charging process."/>
					<div className='w-[250px] md:w-[220px] lg:w-[300px] mx-auto'>
						<h3 className='text-xl text-center font-semibold pb-2'>Driver</h3>
						<img src={featuresOrderFinishDriverImg} alt='/' />
					</div>
					<div className='w-[250px] md:w-[220px] lg:w-[300px] mx-auto'>
						<h3 className='text-xl text-center font-semibold pb-2'>Host</h3>
						<img src={featuresOrderFinishHomeownerImg} alt='/' />
					</div>
				</div>


				<div className='grid grid-cols-1 md:grid-cols-3 items-center gap-4 p-6 bg-gray-100 border border-gray-100 rounded-xl mx-8'>
					<Insights title="Transparent Transactions and Tracking" content="Both EV drivers and homeowners can maintain a clear overview of their charging transactions and financial history. Charbors ensures transparency and accountability, providing a secure platform for all users."/>
					<div className='w-[250px] md:w-[220px] lg:w-[300px] mx-auto'>
						<h3 className='text-xl text-center font-semibold pb-2'>Driver</h3>
						<img src={featuresHistoryDriverImg} alt='/' />
					</div>
					<div className='w-[250px] md:w-[220px] lg:w-[300px] mx-auto'>
						<h3 className='text-xl text-center font-semibold pb-2'>Host</h3>
						<img src={featuresHistoryHomeownerImg} alt='/' />
					</div>
				</div>
			</div>
		</div>
	)
}

export default Features
