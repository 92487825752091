import React from 'react'
import logoImg from '../assets/logo.svg';
import {
	FaFacebookF,
	FaTwitter,
	FaInstagram,
 	FaLinkedinIn} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {
  GooglePlayButton,
  AppStoreButton,
  ButtonsContainer,
} from "react-mobile-app-button";

const Footer = () => {
	const APKUrl =
		"https://play.google.com/store/apps/details?id=com.charbors.android";
	const iOSUrl = "https://apps.apple.com/us/app/charbors/id6460979788";
	const iOSUrlCharger = "https://apps.apple.com/us/app/charbors-charger/id6460979976"
   return (
	   <div className='mb-2'>
			<span className='block border-b-[0.1rem] my-4 border-zinc-800'></span>
			<div className="grid grid-cols-1 md:grid-cols-3 items-center mx-8">
			 <h1 className='text-3xl text-center font-semibold'>Download Now</h1>
			 <div className="flex flex-col justify-center items-center">
				 <h3 className='text-xl text-center font-semibold'>For Driver</h3>
				 <ButtonsContainer direction={"column"}>
				 <AppStoreButton
					 url={iOSUrl}
					 theme={"dark"}
					 className={"custom-style"}
				 />
				 </ButtonsContainer>
			 </div>
			 <div className="flex flex-col justify-center items-center">
				 <h3 className='text-xl text-center font-semibold'>For Host</h3>
				 <AppStoreButton
					 url={iOSUrlCharger}
					 theme={"dark"}
					 className={"custom-style"}
				 />
			 </div>
			</div>
		 	<span className='block border-b-[0.1rem] my-4 border-zinc-800'></span>
			<div className='flex justify-between ml-5 mb-10'>

				<div className='flex md:flex-row flex-col'>
					<h2 className='inline'><Link to="/support" className='mx-5 my-2 sm:my-0 hover:text-charbor-blue cursor-pointer'>Support</Link></h2>
					<h2 className='inline'><Link to="/terms-of-service" className='mx-5 my-2 sm:my-0 hover:text-charbor-blue cursor-pointer'>Terms</Link></h2>
					<h2 className='inline'><Link to="/privacy-policy" className='mx-5 my-2 sm:my-0 hover:text-charbor-blue cursor-pointer'>Privacy Policy</Link></h2>
				</div>

				<div className='flex flex-row'>
					<FaFacebookF className='text-lg ml-8 text-indigo-500 cursor-pointer' onClick={() => window.open('https://www.facebook.com/profile.php?id=61555058130816', '_blank')}/>
					<FaTwitter className='text-lg ml-8 text-indigo-500 cursor-pointer' onClick={() => window.open('https://twitter.com/charbors_corp', '_blank')}/>
					<FaInstagram className='text-lg ml-8 text-indigo-500 cursor-pointer' onClick={() => window.open('https://www.instagram.com/charbors_corp', '_blank')}/>
					<FaLinkedinIn className='text-lg mx-8 text-indigo-500 cursor-pointer' onClick={() => window.open('https://www.linkedin.com/company/charbors/', '_blank')}/>
				</div>
	  		</div>

			<div className='flex justify-center'>
				<div>
					<p className='inline'>Made by </p><h3 className='inline'>Charbors</h3><p className='inline'>. All right reserved</p>
				</div>
			</div>
	   </div>
   )
}

export default Footer
