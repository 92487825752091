import React, { useState } from 'react'
import './Support.css'; // Import the CSS file
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';

function Support() {
  const lastUpdateDate = "December 26, 2023";

  const faqDriverData = [
    {
      question: 'How can I request charging?',
      answer: 'Open the home screen -> Select the map pin -> Press the blue icon in the bubble -> Press the "Request charging" button on the pop-up screen.',
    },
    {
      question: 'Why is there no "Request charging" button on some pop-up screen?',
      answer: 'This is because the EV charger is not available right now.',
    },
    {
      question: 'Why did my charging request not go through after pressing the "Request charging" button?',
      answer: 'There could be several reasons; here are some possible solutions: 1. Ensure that the app is authorized to track user location. 2. Make sure the location services are enabled. 3. Ensure that at least one car has been added. 4. Ensure that at least one payment method has been added.',
    },
    {
      question: 'How do I add a car in the app?',
      answer: 'Open the home screen -> Press the "Setting" button in the top right corner -> Select "Manage Car" -> Press "Add Car" button.',
    },
    {
      question: 'How do I add a payment method in the app?',
      answer: 'Open the home screen -> Press the "Setting" button in the top right corner -> Select "Payment Methods" -> Press "Add Payment Method" button.',
    },
  ];


  const faqHomeownerData = [
    {
      question: 'How do I add a EV charger in the app?',
      answer: 'Open the home screen -> Press the "Setting" button in the top right corner -> Select "Manage Charger" -> Press "Add Charger" button.',
    },
    {
      question: 'Why can\'t I set the EV charger as online?',
      answer: 'There could be several reasons; here are some possible solutions: 1. Ensure that the charging price is not set to zero. 2. Make sure your financial account (bank account) is linked.',
    },
    {
      question: 'How do I change the charging price?',
      answer: 'Open the home screen -> Select the "Price" view -> Enter the amount -> Press the "Update" button.',
    },
    {
      question: 'How do I link my financial account (bank account)?',
      answer: 'Open the home screen -> Press the "Setting" button in the top right corner -> Select "Finance" -> Press "Create Stripe Account" button.',
    },
    {
      question: 'What is Stripe?',
      answer: 'Stripe is a third-party online transaction platform. Charbors uses Stripe to process user charging transactions.',
    },
    {
      question: 'Why do I need to create a Stripe account?',
      answer: 'Charbors uses Stripe to process user charging transactions and payout the balance amount to the user\'s bank account through Stripe. Charbors does not collect the personal information users input when creating a Stripe account.',
    },
  ];


  const [expandedDriverIndex, setExpandedDriverIndex] = useState(null);
  const [expandedHomeownerIndex, setExpandedHomeownerIndex] = useState(null);

  const handleDriverToggle = (index) => {
    setExpandedDriverIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const handleHomeownerToggle = (index) => {
    setExpandedHomeownerIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="support-container">
        <h1>Frequently Asked Questions</h1>
        <p className="text-gray-600 text-center mb-4">Last Update: {lastUpdateDate}</p>
        <br/>
        <h2 className="text-charbor-blue">Charbors App - For Driver</h2>
        <section id="faq-driver-section">
          {faqDriverData.map((faq, index) => (
            <div key={index}>
              <div onClick={() => handleDriverToggle(index)} style={{ cursor: 'pointer', marginBottom: 10 }}>
                <h2 className="flex flex-row gap-4">
                  {faq.question}{' '}
                  {expandedDriverIndex === index ? <FaAngleUp /> : <FaAngleDown />}
                </h2>
              </div>
              {expandedDriverIndex === index && <p>{faq.answer}</p>}
            </div>
          ))}
        </section>
        <br/>
        <h2 className="text-charbor-blue">Charbors EV Charger App - For Homeowner</h2>
        <section id="faq-homeowner-section">
          {faqHomeownerData.map((faq, index) => (
            <div key={index}>
              <div onClick={() => handleHomeownerToggle(index)} style={{ cursor: 'pointer', marginBottom: 10 }}>
                <h2  className="flex flex-row gap-4">
                  {faq.question}{' '}
                  {expandedHomeownerIndex === index ? <FaAngleUp /> : <FaAngleDown />}
                </h2>
              </div>
              {expandedHomeownerIndex === index && <p>{faq.answer}</p>}
            </div>
          ))}
        </section>
        <br/><br/>
        <div className="contact-section">
          <p>If you have any other questions, please feel free to contact us at <a href="mailto:contact@charbors.com">contact@charbors.com</a>.</p>
        </div>
    </div>
  );
}

export default Support;
