// BlogsPage.js
import React,  { useState, useEffect }  from 'react';
import { Link } from 'react-router-dom';
import { app, db } from '../../firebase/firebase';
import { collection, onSnapshot } from 'firebase/firestore';
import Aos from 'aos'
import 'aos/dist/aos.css'
import Navbar from '../navbar/Navbar'
import Footer from '../Footer'
const BlogsPage = () => {
    const [data, setData] = useState([]);

  useEffect(() => {
     Aos.init({ duration: 2500 })

     const colRef = collection(db, 'blogs');

     const unsubListener = onSnapshot(colRef, snapshot => {
       setData(snapshot.docs.map(doc => ({
         id: doc.id,
         data: doc.data()
       })));
     });

     return unsubListener; // <- don't forget to return the unsubscribe function!
  }, [])

  // Sample blog data (replace with actual data fetched from API)
  const blogPosts = [
    {
      id: 1,
      title: 'Lorem ipsum dolor sit amet',
      thumbnail: 'https://via.placeholder.com/150',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
      id: 2,
      title: 'Consectetur adipiscing elit',
      thumbnail: 'https://via.placeholder.com/150',
      content: 'Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    // Add more blog posts as needed
  ];

  return (
    <div className='lg:mx-auto max-w-5xl sm:mx-4 text-white'>
      <Navbar />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-4">Blogs</h1>
        <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-4">
          {data.map((post) => (
            <div key={post.id} className="bg-white shadow-md rounded-lg overflow-hidden">
              <div className="p-4">
                <h2 className="text-xl font-semibold mb-2">{post.data.title}</h2>
                {/* Add more details like author, date, etc., if available */}
                <p className="text-gray-600">{truncateText(post.data.text)}...</p>
                <div className="text-blue-500 mt-2 inline-block">
                <Link to={`/blogs/${encodeURIComponent(post.data.title)}`} state={{ id: post.id }}>Read more</Link></div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

function truncateText(text) {
  // 截取文本的前 512 个字符
  const truncated = text.slice(0, 512);
  // 如果截取的部分与原始文本不相等，则添加省略号
  if (truncated !== text) {
    return truncated + '...';
  } else {
    return text;
  }
}

export default BlogsPage;
