import React from 'react'
import {
	StarIcon,
	DocumentTextIcon,
	GlobeAltIcon,
	MenuAlt2Icon,
	MailOpenIcon,
	PaperClipIcon } from '@heroicons/react/outline'
import RobustWorkflow from './RobustWorkflow'


const Workflow = () => {

	return (
		<div className='container mx-auto text-center'>
			<div data-aos='zoom-in'>
				<h1 className='text-3xl mx-16 md:text-5xl'>Join the Charbors Community</h1>
				<p className='mx-8 md:mx-16 lg:mx-48 px-4 text-xl font-medium my-3 py-4'>Overcoming EV Charging Challenges Together At Charbors, we understand the challenges faced by EV owners and strive to provide innovative solutions.<br/>Join our community if you encounter any of the following concerns:</p>
			</div>

			<div data-aos='zoom-in' className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-5'>
				<RobustWorkflow icon={StarIcon} title="Limited Charging Infrastructure" content="In areas with insufficient public charging stations, Charbors empowers homeowners to share their EV chargers, contributing to a greener future while earning passive income. Become a local charging hero and support the growing EV community."/>
				<RobustWorkflow icon={DocumentTextIcon} title="Convenient Charging Near Attractions" content="If your home is closer to popular attractions than public charging stations, Charbors allows you to provide convenient charging options for EV drivers, capitalizing on your prime location."/>
				<RobustWorkflow icon={GlobeAltIcon} title="Maximize Your Solar Investment" content="For homeowners with solar panels, Charbors offers an opportunity to optimize your investment by sharing your EV charger. Potentially earn more than selling excess energy back to the grid while supporting sustainable transportation."/>
				<RobustWorkflow icon={MenuAlt2Icon} title="Strengthen Community Bonds" content="Foster a sense of community by sharing your EV charger with neighbors in need. Charbors makes it easy to become a friendly charging host and promote eco-friendly practices within your neighborhood."/>
			</div>

			<span className='block border-b-[0.1rem] my-14 border-zinc-800'></span>
		</div>
  )
}

export default Workflow
