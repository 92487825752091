import React, { useEffect } from 'react'
import './PrivacyPolicy.css'; // Import the CSS file

function PrivacyPolicy() {
  const lastUpdateDate = "January 8, 2024";
  return (
        <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p className="text-gray-600 text-center mb-4">Last Update: {lastUpdateDate}</p>
        <section id="introduction">
            <h2>1. Introduction</h2>
            <p>This Privacy Policy ("Policy") explains how Charbors collects, uses, and protects your personal information when you use our platform. By using our platform, you consent to the practices described in this Policy.</p>
        </section>

        <section id="information-collect">
            <h2>2. Information We Collect</h2>

            <p>A. Information You Provide Directly to Us</p>
            <p>When you use the Charbors Platform, we collect the information you provide, usage information, and information about your device. The categories of personal information we collect will depend on how you interact with us, our Services, and the requirements of applicable law. Personal information we may collect includes:</p>

            <ul>
                <li>Account Registration. When you create an account with Charbors, we will collect the information you provide us, such as your name, email address as well as phone number.</li>

                <li>Charging Network Data: When you use Charbors Service, we may collect information that will be associated with you such as charging session date, start time, end time, location, duration, fees associated with each charging session, charging price, and energy load information such as current and voltage.</li>

                <li>Host Information: If you apply to be a Host, we will collect the information you provide in your application, including your name, email address, phone number, physical address.</li>

                <li>Guest Information: We will collect the information you provide us, such as your payment information, as well as information about your vehicle such as license plate number, make, model, color, and/or information about your EV charger.</li>
            </ul>

            <p>B. Information We Collect When You Use the Charbors Platform</p>
            <p>Location Information: We may collect location information (including GPS and WiFi data) differently depending on your device permissions as well as whether you are using the platform as a Guest or Host.</p>

            <p>Usage Information: Such as the pages or content you view, searches for listings, charging requests you have made, and other actions on the Charbors Platform.</p>

            <p>Log Data and Device Information: Such as details about how you’ve used the Charbors Platform (including if you clicked on links to third-party applications), IP address, access dates and times, hardware and software information, device information, device event information, unique identifiers, crash data, and the pages you’ve viewed or engaged with before or after using the Charbors Platform. We may collect this information even if you haven’t created an Charbors account or logged in.</p>

            <p>Cookies and Similar Technologies As Described in Our Cookie Policy: We, as well as third parties that provide content, advertising, or other functionality on our Services, may use cookies, pixel tags, and other technologies (“Technologies”) to automatically collect information through your use of our Services.</p>

            <p>Payment Transaction Information: Such as payment instrument used, date and time, payment amount, payment instrument expiration date and billing postcode, and other related transaction details.</p>

            <p>Payment Information: Charbors collects your payment information necessary for accessing the home EV chargers that are not free-for-use. Charbors does not store any of your payment card information, such as credit card numbers, Google Pay or Apple Pay information, within the Charbors platforms, but rather uses a secure third-party platform that passes very stringent data protection and security standards. Charbors is PCI compliant via Stripe. While we do not directly collect or store any payment card information entered through our Services, we may receive information associated with your payment card information (e.g., your billing details).</p>

            <p>Payouy Information: Charbors does not store your government identification number (such as social security number) as well as the banking information you provide us, including your bank routing numbers, and tax information, within the Charbors platforms, but rather uses a secure third-party platform that passes very stringent data protection and security standards.</p>

            <p>We collect various types of information, including:</p>

            <ul>
                <li>Personal information provided by you, such as your name, email address, phone numbers and payment details.</li>
                <li>Information about your usage of our platform, including location data and usage patterns.</li>
                <li>Communications between you and Charbors, such as emails or messages.</li>
                <li>Information provided by other users, such as reviews or ratings.</li>
            </ul>

        </section>

        <section id="how-we-use">
            <h2>3. How We Use Your Information</h2>
            <p>We use your information for the following purposes:</p>

            <ul>
                <li>To facilitate charging and communications between Hosts and Guests.</li>
                <li>To provide customer support and resolve disputes.</li>
                <li>To improve our platform and customize your experience.</li>
                <li>To send you relevant updates and promotional materials.</li>
            </ul>
        </section>

        <section id="information-sharing">
            <h2>4. Information Sharing</h2>
            <p>We may share your information with the following parties:</p>

            <ul>
                <li>Other users, as necessary to facilitate charging.</li>
                <li>Service providers who assist with our operations.</li>
                <li>Legal authorities when required by law or to protect our rights.</li>
            </ul>

            <p>Sharing Between Members: To help facilitate charging or other interactions between Members (who may be located in, or use service providers located in, jurisdictions with varying levels of data protection), we may share information in situations such as:</p>

            <ul>
                <li>Between Guest(s) and Host(s) when:</li>
                <ul>
                    <li>You as a Guest made a charging request, certain information is shared with the Host to coordinate the charging, such as profile, name, phone number, current location, and information about your vehicle such as license plate number, make, model and color.</li>
                    <li>You as a Host accepted a charging request, certain information is shared with the Guest to coordinate the charging, such as your profile, name, phone number, listing address, charging price, energy load information such as current and voltage, and other information you choose to share and submit.</li>
                </ul>
            </ul>

        </section>

        <section id="data-security">
            <h2>5. Data Security</h2>
            <p>We take measures to protect your information. Unfortunately, no system is 100% secure, and we cannot ensure or warrant the security of any information you provide to us. To the fullest extent permitted by applicable law, we do not accept liability for unauthorized access, use, disclosure, or loss of personal information.</p>
        </section>

        <section id="your-choices">
            <h2>6. Your Choices</h2>
            <p>You can review, edit, and delete your personal information. You can also adjust your communication preferences.</p>
        </section>

        <section id="contact-us">
            <h2>7. Contact Us</h2>
            <p>If you have questions or concerns about this Policy, please contact us at contact@charbors.com.</p>
        </section>

        <section id="changes-to-policy">
            <h2>8. Changes to Policy</h2>
            <p>We may update this Policy, and any changes will be posted on our platform. Your continued use of our platform after such changes will constitute your acceptance of the updated Policy.</p>
        </section>

    </div>
  );
}

export default PrivacyPolicy;
