import React, { useEffect } from 'react'
import './Terms.css'; // Import the CSS file

function Terms() {
  const lastUpdateDate = "December 22, 2023";
  return (
        <div className="terms-container">
      <h1>Terms of Service</h1>
      <p className="text-gray-600 text-center mb-4">Last Update: {lastUpdateDate}</p>
        <section id="introduction">
            <h2>1. Introduction</h2>
            <p>Welcome to Charbors! These Terms of Service ("Terms") govern your use of our platform that connects homeowners willing to rent their electric vehicle (EV) chargers ("Hosts") with EV drivers seeking charging services ("Guests"). By using our platform, you agree to be bound by these Terms.</p>
        </section>

        <section id="eligibility">
            <h2>2. Eligibility</h2>
            <p>You must be at least 18 years old to use our platform. By using our platform, you represent and warrant that you have the legal capacity to enter into a contract.</p>
        </section>

        <section id="your-account">
            <h2>3. Your Account</h2>
            <p>You are responsible for maintaining the confidentiality of your account information. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.</p>
        </section>

        <section id="hosting-and-booking">
            <h2>4. Hosting and Booking</h2>
            <p>Hosts can create listings for their EV chargers, and Guests can book EV chargers for their use. By using our platform, you agree to abide by the specific terms and conditions outlined in the booking process, as well as any additional agreements made between Hosts and Guests.</p>

        </section>

        <section id="fees-and-payments">
          <h2>5. Fees and Payments</h2>
          <p>Our platform may charge fees for using the service. Details on these fees will be provided separately. You are responsible for all fees and taxes associated with your use of our platform.</p>

          {/* ... Additional content related to fees and payments ... */}
        </section>

        <section id="content-and-intellectual-property">
          <h2>6. Content and Intellectual Property</h2>
          <p>All content on our platform, including but not limited to text, graphics, logos, images, and software, is the property of Charbors or its licensors and is protected by copyright and other intellectual property laws.</p>
        </section>

        <section id="privacy">
          <h2>7. Privacy</h2>
          <p>Please refer to our Privacy Policy for information on how we collect, use, and disclose personal information.</p>
        </section>

        <section id="termination">
          <h2>8. Termination</h2>
          <p>We may terminate or suspend your account at our discretion, with or without notice, if we believe you have violated these Terms.</p>
        </section>

        <section id="disclaimers">
          <h2>9. Disclaimers</h2>
          <p>Our platform is provided "as is" and "as available." We make no warranties or representations regarding the quality, accuracy, or reliability of the platform.</p>
        </section>

        <section id="limitation-of-liability">
          <h2>10. Limitation of Liability</h2>
          <p>We are not liable for any indirect, incidental, special, or consequential damages, or any loss of data, revenue, or profits, arising out of or related to your use of our platform.</p>
        </section>

        <section id="changes-to-terms">
          <h2>11. Changes to Terms</h2>
          <p>We may modify these Terms at any time. We will notify you of any changes, and your continued use of our platform will constitute acceptance of the updated Terms.</p>
        </section>

    </div>
  );
}

export default Terms;
