import React, {useState} from 'react'
import { Link, useLocation } from 'react-router-dom';
import logoImg from '../../assets/logo.svg'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import './navbar.css';

const Navbar = () => {
	const [menuActive, setMenuActive] = useState(false)

	const toggleNav = () => setMenuActive(!menuActive)

	const location = useLocation();

	const ToggleBurger = () => {
		return (
			<div className={menuActive ? 'menu active' : 'menu'}>
			<div className='absolute w-full items-end justify-end flex flex-col sm:hidden'>
				<ul className="flex flex-col sm:flex-row space-y-2 px-8">
					<li>
						{location.pathname === '/' ? (
							<span className="text-blue-500 cursor-not-allowed">Home</span>
						) : (
							<a href="/" className="text-charbor-blue">Home</a>
						)}
					</li>
					<li>
						{location.pathname === '/blogs' ? (
							<span className="text-blue-500 cursor-not-allowed">Blogs</span>
						) : (
							<a href="/blogs" className="text-charbor-blue">Blogs</a>
						)}
					</li>
				</ul>
      </div>
			</div>
		)
	}

	return (
		<div className='mb-20 relative z-10'>
			<div className='h-20 w-full flex justify-between items-center sm:bg-transparent relative '>
				<div className='mx-3'>
					<img src={logoImg} alt='/' className='cursor-pointer'/>
				</div>

				<div onClick={toggleNav} className='flex sm:hidden text-charbor-blue mx-4 cursor-pointer relative'>
					<XIcon className={menuActive ? 'xIcon active' : 'xIcon'}/>
					<MenuIcon className={!menuActive ? 'burger active' : 'burger'}/>
				</div>

				<div className='items-center sm:flex hidden'>
					<ul className="flex space-x-4">
					<li>
						{location.pathname === '/' ? (
							<span className="text-blue-500 cursor-not-allowed">Home</span>
						) : (
							<a href="/" className="text-charbor-blue mx-3">Home</a>
						)}
					</li>
					<li>
						{location.pathname === '/blogs' ? (
							<span className="text-blue-500 cursor-not-allowed">Blogs</span>
						) : (
							<a href="/blogs" className="text-charbor-blue mx-3">Blogs</a>
						)}
					</li>
				</ul>
        </div>
			</div>

			<ToggleBurger />
		</div>
   )
}

export default Navbar
