import React, { useEffect } from 'react'
import PrivacyPolicy from './components/privacyPolicy/PrivacyPolicy'
import Terms from './components/terms/Terms'
import Support from './components/support/Support'
import BlogsPage from './components/blogs/BlogsPage';
import BlogDetailPage from './components/blogs/BlogDetailPage';
import Home from './components/Home';
import ScrollToTop from './components/ScrollToTop'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
   return (
        <Router>
        <ScrollToTop />
           <Routes>
           <Route path="/" element={<Home />} />
           <Route path="/support" element={<Support />} />
           <Route path="/terms-of-service" element={<Terms />} />
           <Route path="/privacy-policy" element={<PrivacyPolicy />} />
           <Route path="/blogs" element={<BlogsPage />} />
           <Route path="/blogs/:title" element={<BlogDetailPage />} />
           </Routes>
        </Router>
   )
}

export default App
