// BlogDetailPage.js
import React,  { useState, useEffect }  from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { app, db } from '../../firebase/firebase';
import { doc, getDoc, collection, onSnapshot } from 'firebase/firestore';
import { marked } from 'marked';
import './BlogDetailPage.css'; // Import the CSS file
import Navbar from '../navbar/Navbar'
import Footer from '../Footer'

const BlogDetailPage = () => {
  const location = useLocation();
  const id = location.state ? location.state.id : null; // 获取文章的ID
  const { title } = useParams();
  // const { id } = useParams(); // Get the blog post ID from the URL params

  // Fetch data for the specific blog post with the given ID
  const [data, setData] = useState('');

useEffect(() => {
   const docRef = doc(db, "blogs", id);
   const snapshot = getDoc(docRef);

   const unsubListener = onSnapshot(docRef, snapshot => {
     if (snapshot.exists) {
     setData(snapshot.data());
   } else {
     setData('');
   }
   });

   return unsubListener; // <- don't forget to return the unsubscribe function!
}, [])

const preprocessContent = (content) => {
  // Replace literal '\n' characters with '<br>' tags
  if (content)
    return content.replace(/\\n/g, '<br>');
  else
    return ''
};

  return (
    <div className="article-container">
      <Navbar />
      {data && (
        <div className="article-content">
          <h1 className="article-title">{data.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: marked(preprocessContent(data.content)) }} />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default BlogDetailPage;
